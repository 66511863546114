import React from 'react';

const Footer = () => (
  <footer style={{ padding: 5, backgroundColor: '#f0f0f0' }}>
    <small>
      AnswerBrawl (c) 2024
    </small>
  </footer>
);

export default Footer;
